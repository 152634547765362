import { h, resolveComponent } from "vue";
import { Options } from "vue-class-component";
import components from "../../common/process/NodesExport";
import DefaultProps from "../../common/process/DefaultNodeProps";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "flowable-process-tree-viewer",
    components: components,
    render(): any {
        console.log("渲染流程树");
        this.nodeMap.clear();
        const processTrees = this.getDomTree(this.dom);
        //插入末端节点
        processTrees.push(h("div", { style: { "text-align": "center" } }, [h("div", { class: { "process-end": true } }, "流程结束")]));
        return h("div", { class: { _root: true }, ref: "_root" }, processTrees);
    },
    emits: ["selectedNode"],
})
export default class ProcessTreeViewer extends BaseFunction {
    private valid = true;
    get setup(): any {
        return this.$store.getters["flowable/design"];
    }
    set setup(val: any) {
        this.$store.dispatch("flowable/setDesign", val).then();
    }
    get nodeMap(): any {
        return this.$store.getters["flowable/nodeMap"];
    }
    set nodeMap(val: any) {
        this.$store.dispatch("flowable/setNodeMap", val).then();
    }
    get selectedNode(): any {
        return this.$store.getters["flowable/selectedNode"];
    }
    set selectedNode(val: any) {
        this.$store.dispatch("flowable/setSelectedNode", val).then();
    }
    get dom() {
        return this.setup.process;
    }

    public getDomTree(node: any): any {
        this.toMapping(node);
        if (this.isPrimaryNode(node)) {
            //普通业务节点
            const childDoms = this.getDomTree(node.children);
            this.decodeAppendDom(node, childDoms);
            return [h("div", { class: { "primary-node": true } }, childDoms)];
        } else if (this.isBranchNode(node)) {
            let index = 0;
            //遍历分支节点，包含并行及条件节点
            const branchItems = node.branchs.map((branchNode: any) => {
                //处理每个分支内子节点
                this.toMapping(branchNode);
                const childDoms = this.getDomTree(branchNode.children);
                this.decodeAppendDom(branchNode, childDoms, { level: index + 1, size: node.branchs.length });
                //插入4条横线，遮挡掉条件节点左右半边线条
                this.insertCoverLine(index, childDoms, node.branchs);
                //遍历子分支尾部分支
                index++;
                return h("div", { class: { "branch-node-item": true } }, childDoms);
            });
            //插入添加分支/条件的按钮
            branchItems.unshift(
                h("div", { class: { "add-branch-btn": true } }, [
                    h(
                        resolveComponent("el-button"),
                        {
                            class: { "add-branch-btn-el": true },
                            size: "default",
                            round: true,
                            onClick: () => this.addBranchNode(node),
                        },
                        () => "添加" + (this.isConditionNode(node) ? "条件" : "分支"),
                    ),
                ]),
            );
            const bchDom = [h("div", { class: { "branch-node": true } }, branchItems)];
            //继续遍历分支后的节点
            const afterChildDoms = this.getDomTree(node.children);
            return [h("div", {}, [bchDom, afterChildDoms])];
        } else if (this.isEmptyNode(node)) {
            //空节点，存在于分支尾部
            const childDoms = this.getDomTree(node.children);
            this.decodeAppendDom(node, childDoms);
            return [h("div", { class: { "empty-node": true } }, childDoms)];
        } else {
            //遍历到了末端，无子节点
            return [];
        }
    }
    //解码渲染的时候插入dom到同级
    public decodeAppendDom(node: any, dom: any, props: any = {}) {
        dom.unshift(
            h(
                resolveComponent(node.type.toLowerCase()),
                {
                    ...props,
                    config: node,
                    ref: node.id,
                    key: node.id,
                    //定义事件，插入节点，删除节点，选中节点，复制/移动
                    onInsertNode: (type: any) => this.insertNode(type, node),
                    onDelNode: () => this.delNode(node),
                    onSelected: () => this.selectNode(node),
                    onCopy: () => this.copyBranch(node),
                    onLeftMove: () => this.branchMove(node, -1),
                    onRightMove: () => this.branchMove(node, 1),
                },
                () => [],
            ),
        );
    }
    //id映射到map，用来向上遍历
    public toMapping(node: any) {
        if (node && node.id) {
            //console.log("node=> " + node.id + " name:" + node.name + " type:" + node.type);
            this.nodeMap.set(node.id, node);
        }
    }
    public insertCoverLine(index: any, doms: any, branchs: any) {
        if (index === 0) {
            //最左侧分支
            doms.unshift(h("div", { class: { "line-top-left": true } }, () => []));
            doms.unshift(h("div", { class: { "line-bot-left": true } }, () => []));
        } else if (index === branchs.length - 1) {
            //最右侧分支
            doms.unshift(h("div", { class: { "line-top-right": true } }, () => []));
            doms.unshift(h("div", { class: { "line-bot-right": true } }, () => []));
        }
    }
    public copyBranch(node: any) {
        const parentNode = this.nodeMap.get(node.parentId);
        const branchNode = this.$tools.deepCopy(node);
        branchNode.name = branchNode.name + "-copy";
        this.forEachNode(parentNode, branchNode, (parent: any, node: any) => {
            const id = this.getRandomId();
            console.log(node, "新id =>" + id, "老nodeId:" + node.id);
            node.id = id;
            node.parentId = parent.id;
        });
        parentNode.branchs.splice(parentNode.branchs.indexOf(node), 0, branchNode);
        this.$forceUpdate();
    }
    public branchMove(node: any, offset: any) {
        const parentNode = this.nodeMap.get(node.parentId);
        const index = parentNode.branchs.indexOf(node);
        const branch = parentNode.branchs[index + offset];
        parentNode.branchs[index + offset] = parentNode.branchs[index];
        parentNode.branchs[index] = branch;
        this.$forceUpdate();
    }
    //判断是否为主要业务节点
    public isPrimaryNode(node: any) {
        return node && (node.type === "ROOT" || node.type === "APPROVAL" || node.type === "CC" || node.type === "DELAY" || node.type === "TRIGGER");
    }
    public isBranchNode(node: any) {
        return node && (node.type === "CONDITIONS" || node.type === "CONCURRENTS");
    }
    public isEmptyNode(node: any) {
        return node && node.type === "EMPTY";
    }
    //是分支节点
    public isConditionNode(node: any) {
        return node.type === "CONDITIONS";
    }
    //是分支节点
    public isBranchSubNode(node: any) {
        return node && (node.type === "CONDITION" || node.type === "CONCURRENT");
    }
    public isConcurrentNode(node: any) {
        return node.type === "CONCURRENTS";
    }
    public getRandomId() {
        return `node_${new Date().getTime().toString().substring(5)}${Math.round(Math.random() * 9000 + 1000)}`;
    }
    //选中一个节点
    public selectNode(node: any) {
        this.selectedNode = node;
        this.$emit("selectedNode", node);
    }
    //处理节点插入逻辑
    public insertNode(type: any, parentNode: any) {
        (this.$refs["_root"] as any).click();
        //缓存一下后面的节点
        const afterNode = parentNode.children;
        //插入新节点
        parentNode.children = {
            id: this.getRandomId(),
            parentId: parentNode.id,
            props: {},
            type: type,
        };
        switch (type) {
            case "APPROVAL":
                this.insertApprovalNode(parentNode);
                break;
            case "CC":
                this.insertCcNode(parentNode);
                break;
            case "DELAY":
                this.insertDelayNode(parentNode);
                break;
            case "TRIGGER":
                this.insertTriggerNode(parentNode);
                break;
            case "CONDITIONS":
                this.insertConditionsNode(parentNode);
                break;
            case "CONCURRENTS":
                this.insertConcurrentsNode(parentNode);
                break;
            default:
                break;
        }
        //拼接后续节点
        if (this.isBranchNode({ type: type })) {
            if (afterNode && afterNode.id) {
                afterNode.parentId = parentNode.children.children.id;
            }
            parentNode.children.children.children = afterNode;
        } else {
            if (afterNode && afterNode.id) {
                afterNode.parentId = parentNode.children.id;
            }
            parentNode.children.children = afterNode;
        }
        this.$forceUpdate();
    }
    public insertApprovalNode(parentNode: any) {
        parentNode.children.name = "审批人";
        parentNode.children.props = this.$tools.deepCopy(DefaultProps.APPROVAL_PROPS);
    }
    public insertCcNode(parentNode: any) {
        parentNode.children.name = "抄送人";
        parentNode.children.props = this.$tools.deepCopy(DefaultProps.CC_PROPS);
    }
    public insertDelayNode(parentNode: any) {
        parentNode.children.name = "延时处理";
        parentNode.children.props = this.$tools.deepCopy(DefaultProps.DELAY_PROPS);
    }
    public insertTriggerNode(parentNode: any) {
        parentNode.children.name = "触发器";
        parentNode.children.props = this.$tools.deepCopy(DefaultProps.TRIGGER_PROPS);
    }
    public insertConditionsNode(parentNode: any) {
        parentNode.children.name = "条件分支";
        parentNode.children.children = {
            id: this.getRandomId(),
            parentId: parentNode.children.id,
            type: "EMPTY",
        };
        parentNode.children.branchs = [
            {
                id: this.getRandomId(),
                parentId: parentNode.children.id,
                type: "CONDITION",
                props: this.$tools.deepCopy(DefaultProps.CONDITION_PROPS),
                name: "条件1",
                children: {},
            },
            {
                id: this.getRandomId(),
                parentId: parentNode.children.id,
                type: "CONDITION",
                props: this.$tools.deepCopy(DefaultProps.CONDITION_PROPS),
                name: "条件2",
                children: {},
            },
        ];
    }
    public insertConcurrentsNode(parentNode: any) {
        parentNode.children.name = "并行分支";
        parentNode.children.children = {
            id: this.getRandomId(),
            parentId: parentNode.children.id,
            type: "EMPTY",
        };
        parentNode.children.branchs = [
            {
                id: this.getRandomId(),
                name: "分支1",
                parentId: parentNode.children.id,
                type: "CONCURRENT",
                props: {},
                children: {},
            },
            {
                id: this.getRandomId(),
                name: "分支2",
                parentId: parentNode.children.id,
                type: "CONCURRENT",
                props: {},
                children: {},
            },
        ];
    }
    public getBranchEndNode(conditionNode: any): any {
        if (!conditionNode.children || !conditionNode.children.id) {
            return conditionNode;
        }
        return this.getBranchEndNode(conditionNode.children);
    }
    public addBranchNode(node: any) {
        if (node.branchs.length < 8) {
            node.branchs.push({
                id: this.getRandomId(),
                parentId: node.id,
                name: (this.isConditionNode(node) ? "条件" : "分支") + (node.branchs.length + 1),
                props: this.isConditionNode(node) ? this.$tools.deepCopy(DefaultProps.CONDITION_PROPS) : {},
                type: this.isConditionNode(node) ? "CONDITION" : "CONCURRENT",
                children: {},
            });
        } else {
            this.toast.warning("最多只能添加 8 项😥");
        }
    }
    //删除当前节点
    public delNode(node: any) {
        console.log("删除节点", node);
        //获取该节点的父节点
        const parentNode = this.nodeMap.get(node.parentId);
        if (parentNode) {
            //判断该节点的父节点是不是分支节点
            if (this.isBranchNode(parentNode)) {
                //移除该分支
                parentNode.branchs.splice(parentNode.branchs.indexOf(node), 1);
                //处理只剩1个分支的情况
                if (parentNode.branchs.length < 2) {
                    //获取条件组的父节点
                    const ppNode = this.nodeMap.get(parentNode.parentId);
                    //判断唯一分支是否存在业务节点
                    if (parentNode.branchs[0].children && parentNode.branchs[0].children.id) {
                        //将剩下的唯一分支头部合并到主干
                        ppNode.children = parentNode.branchs[0].children;
                        ppNode.children.parentId = ppNode.id;
                        //搜索唯一分支末端最后一个节点
                        const endNode = this.getBranchEndNode(parentNode.branchs[0]);
                        //后续节点进行拼接, 这里要取EMPTY后的节点
                        endNode.children = parentNode.children.children;
                        if (endNode.children && endNode.children.id) {
                            endNode.children.parentId = endNode.id;
                        }
                    } else {
                        //直接合并分支后面的节点，这里要取EMPTY后的节点
                        ppNode.children = parentNode.children.children;
                        if (ppNode.children && ppNode.children.id) {
                            ppNode.children.parentId = ppNode.id;
                        }
                    }
                }
            } else {
                //不是的话就直接删除
                if (node.children && node.children.id) {
                    node.children.parentId = parentNode.id;
                }
                parentNode.children = node.children;
            }
            this.$forceUpdate();
        } else {
            this.toast.warning("出现错误，找不到上级节点😥");
        }
    }
    public validateProcess() {
        this.valid = true;
        const err: any = [];
        this.validate(err, this.dom);
        return err;
    }
    public validateNode(err: any, node: any) {
        if ((this.$refs[node.id] as any).validate) {
            this.valid = (this.$refs[node.id] as any).validate(err);
        }
    }
    //更新指定节点的dom
    public nodeDomUpdate(node: any) {
        (this.$refs[node.id] as any).$forceUpdate();
    }
    //给定一个起始节点，遍历内部所有节点
    public forEachNode(parent: any, node: any, callback: any) {
        if (this.isBranchNode(node)) {
            callback(parent, node);
            this.forEachNode(node, node.children, callback);
            node.branchs.map((branchNode: any) => {
                callback(node, branchNode);
                this.forEachNode(branchNode, branchNode.children, callback);
            });
        } else if (this.isPrimaryNode(node) || this.isEmptyNode(node) || this.isBranchSubNode(node)) {
            callback(parent, node);
            this.forEachNode(node, node.children, callback);
        }
    }
    //校验所有节点设置
    public validate(err: any, node: any) {
        if (this.isPrimaryNode(node)) {
            this.validateNode(err, node);
            this.validate(err, node.children);
        } else if (this.isBranchNode(node)) {
            //校验每个分支
            node.branchs.map((branchNode: any) => {
                //校验条件节点
                this.validateNode(err, branchNode);
                //校验条件节点后面的节点
                this.validate(err, branchNode.children);
            });
            this.validate(err, node.children);
        } else if (this.isEmptyNode(node)) {
            this.validate(err, node.children);
        }
    }
}
