import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";

@Options({
    name: "flowable-workspace-header-workspace",
    props: {
        modelValue: Boolean,
    },
    emits: ["update:modelValue"],
})
export default class HeaderWorkspace extends BaseFunction {
    private modelValue: string;

    public to(selected: any) {
        this.$emit("update:modelValue", selected);
    }
    public btnThemes(cur: string): string {
        return this.modelValue === cur ? "btn-themes" : "btn-default";
    }
}
